import { Injectable, inject } from '@angular/core';
import { BaseDataService } from '@orquesta/data/common';
import { Webhook, WebhookPatch } from '@orquesta/models/webhooks/types';
import { WEBHOOKS_API } from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class WebhookService extends BaseDataService<
  Webhook[],
  Webhook,
  Webhook,
  WebhookPatch
> {
  constructor() {
    super(inject(WEBHOOKS_API));
  }

  fetchSecret() {
    return this.http.get<{ secret: string }>(`${this.apiUrl}/secret`);
  }
}
